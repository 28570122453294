import { MeasuringType } from '../api';

export type MeasuringUnit = {
  symbol: string;
  baseUnitMultiplier: number;
};

export const MeasuringUnitCategories: { [key in MeasuringType]: MeasuringUnit[] } = {
  [MeasuringType.UNKNOWN]: [
    {
      symbol: '',
      baseUnitMultiplier: 1
    }
  ],

  [MeasuringType.WEIGHT]: [
    {
      symbol: 'g',
      baseUnitMultiplier: 1
    },
    {
      symbol: 'kg',
      baseUnitMultiplier: 1000
    },
    {
      symbol: 'mg',
      baseUnitMultiplier: 0.001
    }
  ],

  [MeasuringType.LIQUID]: [
    {
      symbol: 'l',
      baseUnitMultiplier: 1
    },
    {
      symbol: 'cl',
      baseUnitMultiplier: 0.01
    },
    {
      symbol: 'ml',
      baseUnitMultiplier: 0.001
    },
    {
      symbol: 'Tropfen',
      baseUnitMultiplier: 0.00005
    }
  ],

  [MeasuringType.SPOONS]: [
    {
      symbol: 'TL',
      baseUnitMultiplier: 1
    },
    {
      symbol: 'EL',
      baseUnitMultiplier: 3
    }
  ],

  [MeasuringType.PACKETS]: [
    {
      symbol: 'Pck.',
      baseUnitMultiplier: 1
    }
  ],

  [MeasuringType.PIECES]: [
    {
      symbol: 'Stk.',
      baseUnitMultiplier: 1
    }
  ],

  [MeasuringType.CUPS]: [
    {
      symbol: 'Tasse',
      baseUnitMultiplier: 1
    }
  ]
};
