import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function BaseLayout({ children }: { children: ReactNode }) {
  const { i18n } = useTranslation('common');

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language, dir: i18n.dir() }} />

      <div className={'min-h-screen w-full'}>{children}</div>
    </>
  );
}
