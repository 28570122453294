import throttle from 'lodash/throttle';
import { useEffect, useMemo, useState } from 'react';

export function useThrottledState<T>(initialValue: T, delay: number): [T, T, (value: T) => void] {
  const [value, setValue] = useState(initialValue);
  const [throttledValue, setThrottledValue] = useState(initialValue);

  const throttledSet = useMemo(() => {
    return throttle(setThrottledValue, delay);
  }, [delay]);

  useEffect(() => {
    throttledSet(value);
  }, [value, throttledSet]);

  return [value, throttledValue, setValue];
}
