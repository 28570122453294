/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserPreview } from '../models/UserPreview';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuthenticationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Login a User
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public login(
        requestBody?: {
            email?: string;
            password?: string;
            deviceName?: string;
        },
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                429: `The request you send does not meet the required validation rules.`,
            },
        });
    }
    /**
     * Logout a User
     * @returns any OK
     * @throws ApiError
     */
    public logout(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/logout',
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Get currently logged in User
     * @returns any OK
     * @throws ApiError
     */
    public me(): CancelablePromise<{
        data: UserPreview;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/me',
            errors: {
                401: `You need to login to make this request.`,
            },
        });
    }
}
