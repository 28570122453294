import { Image } from '../components/Image.tsx';
import { Link } from 'react-router-dom';
import { RecipePreview } from '../../api';
import { Icon } from '../components/Icon.tsx';
import { Time } from '../components/Time.tsx';
import { Difficulty } from './recipe/Difficulty.tsx';
import { MouseEventHandler } from 'react';
import { ProfileImage } from './user/ProfileImage.tsx';

export function RecipePreviewCard({ recipe }: { recipe: RecipePreview }) {
  const addToFavorites: MouseEventHandler = (e) => {
    e.preventDefault();
    console.log('Add to favorites');
  };

  return (
    <Link
      className={
        'group flex flex-col rounded-2xl bg-surface-1 p-2 pb-4 transition-all duration-200 hover:bg-secondary-container active:rounded-3xl'
      }
      to={`/recipe/${recipe.slug}`}
      state={{ data: recipe }}
    >
      <div className={'relative'}>
        <Image
          image={recipe.titleImage}
          alt={recipe.title}
          className={'aspect-4/3 rounded-xl transition-all duration-200 group-active:rounded-2xl'}
        />

        <div className={'absolute left-0 right-0 top-0 flex flex-row justify-between p-2'}>
          <div
            className={
              'flex flex-row gap-1 rounded-lg bg-surface-2 bg-opacity-30 transition-all duration-200 group-active:rounded-xl'
            }
          >
            <button
              onClick={addToFavorites}
              className={'rounded-lg px-2 py-1 hover:bg-surface-3 hover:bg-opacity-20'}
              title={'Add to favorites'}
            >
              <Icon name={'favorite'} />
            </button>
          </div>
        </div>
      </div>

      <h5
        className={
          'mx-2 mb-1 mt-2 line-clamp-2 flex-grow text-lg font-semibold text-on-surface group-hover:text-on-secondary-container'
        }
      >
        {recipe.title}
      </h5>

      <div
        className={
          'mx-2 my-1 flex flex-row items-center gap-1 text-on-surface text-opacity-60 group-hover:text-on-secondary-container'
        }
      >
        <ProfileImage user={recipe.author} />
        <span className={'line-clamp-1 overflow-ellipsis'}>{recipe.author.username}</span>
      </div>

      <div
        className={
          'mx-2 my-1 text-on-surface text-opacity-60 group-hover:text-on-secondary-container'
        }
      >
        <span>
          <Difficulty difficulty={recipe.difficulty} />
        </span>
        <span className={'float-right'}>
          <Time time={new Date(recipe.preparationTime)} />
        </span>
      </div>
    </Link>
  );
}
