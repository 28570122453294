'use client';

import { ReactNode, RefObject, useEffect } from 'react';
import { Icon } from './Icon.tsx';
import { TransitionGroup } from 'react-transition-group';
import { FadeTransition } from './transitions/FadeTransition.tsx';
import { ModalCardTransition } from './transitions/ModalCardTransition.tsx';
import { Portal } from './Portal.tsx';

export function Modal({
  isOpen,
  onClose,
  title,
  children,
  footer,
  disableBodyScroll = true
}: {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: ReactNode;
  footer?: ReactNode;
  disableBodyScroll?: boolean;
}) {
  useEffect(() => {
    if (disableBodyScroll) {
      if (isOpen) {
        // Disable body scroll
        document.body.classList.add('overflow-hidden');
      } else {
        // Enable body scroll
        document.body.classList.remove('overflow-hidden');
      }
    }
  }, [disableBodyScroll, isOpen]);

  return (
    <Portal to={document.body}>
      <TransitionGroup className={'relative z-50'}>
        {isOpen && (
          <FadeTransition>
            {/* Modal-Backdrop */}
            {(ref: RefObject<HTMLDivElement>) => (
              <div
                ref={ref}
                className={'fixed inset-0 overflow-y-auto bg-background bg-opacity-60'}
                aria-hidden="true"
              />
            )}
          </FadeTransition>
        )}

        {isOpen && (
          <ModalCardTransition>
            {/* Modal-Card */}
            {(ref: RefObject<HTMLDivElement>) => (
              <div
                ref={ref}
                className="fixed inset-0 w-screen overflow-y-auto"
                onClick={(e) => {
                  e.target === e.currentTarget && onClose();
                }}
              >
                <div
                  className={
                    'mt-8 flex min-h-screen w-screen flex-col overflow-clip rounded-lg bg-surface-1 shadow-lg md:mx-auto md:my-8 md:min-h-0 md:max-w-2xl'
                  }
                >
                  <header
                    className={
                      'sticky top-0 z-10 flex h-16 w-full flex-row items-center border-b border-outline bg-surface-1 px-8'
                    }
                  >
                    <h2 className={'text-black flex-grow text-lg font-semibold uppercase'}>
                      {title ?? ''}
                    </h2>

                    <button
                      className={
                        'flex size-8 items-center justify-center rounded-full p-0 hover:bg-surface-2'
                      }
                      onClick={onClose}
                    >
                      <Icon name={'close'} />
                    </button>
                  </header>

                  <div className={'flex-grow p-8'}>{children}</div>

                  {footer && (
                    <footer
                      className={
                        'sticky bottom-0 z-10 flex h-16 w-full flex-row items-center border-t border-outline bg-surface-1 px-8'
                      }
                    >
                      {footer}
                    </footer>
                  )}
                </div>
              </div>
            )}
          </ModalCardTransition>
        )}
      </TransitionGroup>
    </Portal>
  );
}
