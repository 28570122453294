import { createContext, ReactNode, useContext, useMemo } from 'react';
import { CookbookApi } from '../api';
import { useAuth } from './AuthContext.tsx';
import { useTranslation } from 'react-i18next';

const CookbookApiContext = createContext<CookbookApi>({} as CookbookApi);

export const CookbookApiProvider = function ({ children }: { children: ReactNode }) {
  const { token } = useAuth();
  const { i18n } = useTranslation();

  const cookbookApi = useMemo(() => {
    return new CookbookApi({
      BASE: import.meta.env.VITE_API_URL,
      TOKEN: token,
      HEADERS: {
        'Accept-Language': i18n.language
      }
    });
  }, [token, i18n.language]);

  return <CookbookApiContext.Provider value={cookbookApi}>{children}</CookbookApiContext.Provider>;
};

export const useCookbook = () => useContext(CookbookApiContext);
