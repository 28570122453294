/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DashboardSection } from '../models/DashboardSection';
import type { Image } from '../models/Image';
import type { Pagination } from '../models/Pagination';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MiscellaneousService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Dashboard
     * Get the general (unauthorized) or personalized (authorized) dashboard
     * @returns any OK
     * @throws ApiError
     */
    public getDashboard(): CancelablePromise<({
        data: Array<DashboardSection>;
    } & Pagination)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/dashboard',
        });
    }
    /**
     * Upload an Image
     * @param formData
     * @returns any OK
     * @throws ApiError
     */
    public uploadImage(
        formData?: {
            image?: Blob;
        },
    ): CancelablePromise<{
        data: Image;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/image',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `You need to login to make this request.`,
            },
        });
    }
}
