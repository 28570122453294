import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';

export function TextButton({
  children,
  href,
  onClick,
  disabled = false,
  className
}: {
  children: ReactNode;
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}) {
  return (
    <Link
      to={href ?? ''}
      role={'button'}
      aria-disabled={disabled}
      onClick={disabled ? (e) => e.preventDefault() : onClick}
      className={twMerge(
        'inline-block rounded-md bg-primary bg-opacity-0 px-4 py-2 text-on-background transition-all duration-200',
        !disabled
          ? 'hover:bg-opacity-10 focus:bg-opacity-10 active:bg-opacity-15'
          : 'cursor-not-allowed',
        className
      )}
    >
      {children}
    </Link>
  );
}
