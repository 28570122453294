import { TimeLineItem } from '../../components/timeline/TimeLineItem.tsx';
import { TimeLine } from '../../components/timeline/TimeLine.tsx';
import { Instruction } from '../../../api';
import { Image } from '../../components/Image.tsx';

export function InstructionsList({ instructions }: { instructions: Instruction[] }) {
  return (
    <TimeLine>
      {instructions.map((instruction, index) => (
        <TimeLineItem title={`${index + 1}. Schritt`} key={index}>
          {instruction.image && <Image image={instruction.image} alt={instruction.description} />}

          {instruction.description}
        </TimeLineItem>
      ))}
    </TimeLine>
  );
}
