/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MeasuringType {
    UNKNOWN = 'UNKNOWN',
    WEIGHT = 'WEIGHT',
    LIQUID = 'LIQUID',
    SPOONS = 'SPOONS',
    PACKETS = 'PACKETS',
    PIECES = 'PIECES',
    CUPS = 'CUPS',
}
