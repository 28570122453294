import { useSearch } from '../../../contexts/SearchContext.tsx';
import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Food } from '../../../api';
import { useThrottledState } from '../../../hooks/throttledState.ts';
import { useCookbookLoader } from '../../../hooks/loader.ts';
import { SearchFilterModal } from './SearchFilterModal.tsx';
import { useCookbook } from '../../../contexts/ApiContext.tsx';
import { BlockLoadingSkeleton } from '../../components/loaders/BlockLoadingSkeleton.tsx';

type FoodFilterModalContentRef = {
  getSelectedFood: () => string[] | undefined;
};

export function FoodFilterModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const { filters, setFilters } = useSearch();
  const contentRef = useRef<FoodFilterModalContentRef>();

  function apply() {
    const selectedFood = contentRef.current?.getSelectedFood();
    setFilters({ ...filters, food: selectedFood });
    onClose();
  }

  function clear() {
    setFilters({ ...filters, food: [] });
    onClose();
  }

  return (
    <SearchFilterModal isOpen={isOpen} onClose={onClose} onApply={apply} onClear={clear}>
      <FoodFilterModalContent ref={contentRef} />
    </SearchFilterModal>
  );
}

const FoodFilterModalContent = forwardRef(
  (_: unknown, ref: Ref<FoodFilterModalContentRef | undefined>) => {
    const cookbookApi = useCookbook();
    const { filters } = useSearch();

    const [initialSelectedFoodLoaded, setInitialSelectedFoodLoaded] = useState(false);
    const [selectedFood, setSelectedFood] = useState<Food[]>([]);
    const [searchQuery, throttledSearchQuery, setSearchQuery] = useThrottledState('', 500);

    const [isLoading, food, error] = useCookbookLoader(
      (api) => api.search.searchFood(throttledSearchQuery),
      [throttledSearchQuery]
    );

    useEffect(() => {
      if (filters.food) {
        cookbookApi.search
          .searchFood(undefined, Object.assign({}, filters.food))
          .then((result) => setSelectedFood(result.data))
          .catch((error) => console.error(error))
          .finally(() => setInitialSelectedFoodLoaded(true));
      } else {
        setInitialSelectedFoodLoaded(true);
      }
    }, []);

    useEffect(() => {
      if (error) console.error(error);
    }, [error]);

    useImperativeHandle(
      ref,
      () => ({
        getSelectedFood: () =>
          selectedFood.map((food) => food.id).filter((id) => id !== undefined) as string[]
      }),
      [selectedFood]
    );

    return !initialSelectedFoodLoaded ? (
      <>
        <BlockLoadingSkeleton />
      </>
    ) : (
      <>
        <input type={'text'} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />

        <ul>
          {selectedFood?.map((food) => (
            <li key={food.id}>
              <button
                onClick={() => {
                  setSelectedFood(selectedFood.filter((selected) => selected.id !== food.id));
                }}
              >
                {food.name}
              </button>
            </li>
          ))}
        </ul>

        <hr />

        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <ul>
            {food?.map((food) => (
              <li key={food.id}>
                <label>
                  <input
                    type={'checkbox'}
                    checked={selectedFood.some((selected) => selected.id === food.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedFood([...selectedFood, food]);
                      } else {
                        setSelectedFood(selectedFood.filter((selected) => selected.id !== food.id));
                      }
                    }}
                  />
                  {food.name}
                </label>
              </li>
            ))}
          </ul>
        )}
      </>
    );
  }
);
