import { Helmet } from 'react-helmet';
import { Ingredient, Instruction, Recipe, RecipePreview } from '../../api';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import TopBarLayout from '../layouts/TopBarLayout.tsx';
import { Image } from '../components/Image.tsx';
import { IngredientsList } from '../cookbook/recipe/IngredientsList.tsx';
import { InstructionsList } from '../cookbook/recipe/InstructionsList.tsx';
import { useCookbookLoader } from '../../hooks/loader.ts';
import { Difficulty } from '../cookbook/recipe/Difficulty.tsx';
import { Time } from '../components/Time.tsx';

export default function RecipePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [, recipe, error] = useCookbookLoader<Recipe | RecipePreview>(
    (api) => api.recipes.getRecipe(id ?? ''),
    [id],
    location.state?.data
  );

  useEffect(() => {
    if (error?.status === 404) {
      navigate('/404', { state: { error: 'recipe not found' } });
    }
  }, [error]);

  return (
    <TopBarLayout title={recipe?.title}>
      <Helmet>
        <title>{recipe?.title}</title>
      </Helmet>

      {recipe && (
        <Image
          className={'aspect-16/9 min-h-52 w-full rounded-lg object-cover'}
          image={recipe?.titleImage}
          alt={recipe?.title}
        />
      )}

      <div
        className={
          'relative z-0 mx-auto -mt-16 min-h-32 w-2/3 rounded-lg bg-secondary !text-center text-on-secondary'
        }
      >
        <h1 className={'font-baloo text-2xl font-black'}>{recipe?.title}</h1>

        <div className={'grid grid-cols-3'}>
          <div>
            <p>
              <Difficulty difficulty={recipe?.difficulty ?? 0} />
            </p>
            <p>Difficulty</p>
          </div>

          <div>
            <p>
              <Time time={new Date(recipe?.preparationTime ?? 0)} />
            </p>
            <p>Cook time</p>
          </div>

          <div>
            <p>{recipe?.author.username} minutes</p>
            <p>Author</p>
          </div>
        </div>
      </div>

      <Link to={`/user/${recipe?.author.username}`}>{recipe?.author.username}</Link>

      {recipe?.description && <p>{recipe?.description}</p>}

      {isRecipe(recipe) && (
        <div className={'mb-4 rounded-lg bg-surface-1 p-4'}>
          <h2>Ingredients</h2>
          <IngredientsList ingredients={recipe.ingredients} initialServings={recipe.servings} />
        </div>
      )}

      {isRecipe(recipe) && (
        <div className={'rounded-lg bg-surface-1 p-4'}>
          <InstructionsList instructions={recipe.instructions} />
        </div>
      )}
    </TopBarLayout>
  );
}

function isRecipe(data: Recipe | RecipePreview | undefined): data is Recipe & {
  ingredients: Ingredient[];
  instructions: Instruction[];
} {
  return (
    (data as Recipe)?.ingredients !== undefined && (data as Recipe)?.instructions !== undefined
  );
}
