import { Ingredient } from '../../../api';
import { Fragment, useMemo, useState } from 'react';
import { calculateMaximalUnit } from '../../../utils/measuringUnitUtils.ts';
import { Fraction } from '../../components/Fraction.tsx';

export function IngredientsList({
  ingredients,
  initialServings
}: {
  ingredients: Ingredient[];
  initialServings: number;
}) {
  const [servings, setServings] = useState(initialServings);
  const scaledIngredients = useMemo(() => {
    return ingredients.map((ingredient) => {
      return {
        ...ingredient,
        scaledAmount: calculateMaximalUnit(
          (ingredient.amount / initialServings) * servings,
          ingredient.measuringType
        )
      };
    });
  }, [ingredients, initialServings, servings]);

  return (
    <>
      <div className={'grid grid-cols-2 gap-2'}>
        {scaledIngredients.map((ingredient) => (
          <Fragment key={ingredient.id}>
            <div className={'text-right'}>
              <Fraction number={ingredient.scaledAmount.quantity} />
              &nbsp;
              {ingredient.scaledAmount.unit.symbol}
            </div>
            <div>{ingredient.food.name}</div>
          </Fragment>
        ))}
      </div>

      <div className={'flex flex-row'}>
        <label htmlFor={'servings'}>Servings</label>
        <input
          id={'servings'}
          type={'number'}
          value={servings}
          onChange={(event) => setServings(parseInt(event.target.value))}
        />
      </div>
    </>
  );
}
