import { Icon } from '../../components/Icon.tsx';
import { twMerge } from 'tailwind-merge';

export function Difficulty({ difficulty }: { difficulty: number }) {
  return Array(5)
    .fill(1)
    .map((_, index) => (
      <Icon
        name={'restaurant_menu'}
        key={index}
        className={twMerge(index > difficulty && 'opacity-30')}
      />
    ));
}
