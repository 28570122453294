import { twMerge } from 'tailwind-merge';
import { ForwardedRef, forwardRef } from 'react';

function IconComponent(
  {
    name,
    filled = false,
    className
  }: {
    name: string;
    filled?: boolean;
    className?: string;
  },
  ref: ForwardedRef<HTMLElement>
) {
  return (
    <i ref={ref} className={twMerge('material-symbol select-none', filled && 'icon-style-filled', className)}>
      {name}
    </i>
  );
}

export const Icon = forwardRef(IconComponent);
