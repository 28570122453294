'use client';

import { ReactNode, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Slider, SliderRef } from './Slider.tsx';
import { Icon } from '../Icon.tsx';

export function SliderWithControls({
  children,
  className
}: {
  children: ReactNode;
  className?: string;
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slider = useRef<SliderRef>();

  function scrollToNext() {
    slider.current?.scrollToItem(currentIndex + 1, true);
  }

  function scrollToPrevious() {
    slider.current?.scrollToItem(currentIndex - 1, true);
  }

  return (
    <div className={twMerge('group relative', className)}>
      <Slider ref={slider} onSlide={setCurrentIndex}>
        {children}
      </Slider>

      <div
        className={`pointer-events-none absolute bottom-4 left-4 right-4 z-10 flex flex-row items-center justify-between transition-all duration-1000 lg:flex`}
      >
        <button
          type="button"
          onClick={scrollToPrevious}
          className="pointer-events-auto grid h-8 w-8 place-items-center rounded-full bg-tertiary text-on-tertiary opacity-0 group-hover:opacity-100"
        >
          <Icon name={'chevron_left'} />
        </button>
        <div className="flex gap-1">
          {slider.current?.itemRefs.map((_image, index) => (
            <div
              key={index}
              className={twMerge(
                'h-1.5 w-1.5 rounded-full bg-tertiary',
                index !== currentIndex && 'opacity-50'
              )}
            />
          ))}
        </div>
        <button
          type="button"
          onClick={scrollToNext}
          className="pointer-events-auto grid h-8 w-8 place-items-center rounded-full bg-tertiary text-on-tertiary opacity-0 group-hover:opacity-100"
        >
          <Icon name={'chevron_right'} />
        </button>
      </div>
    </div>
  );
}
