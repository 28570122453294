/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Food } from '../models/Food';
import type { RecipePreview } from '../models/RecipePreview';
import type { Tag } from '../models/Tag';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SearchService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Search Recipes
     * @param q Search query.
     * @param minTime Minimum preparation time a recipe should have.
     * @param maxTime Maximum preparation time a recipe should have.
     * @param minDifficulty Minimum difficulty a recipe should have.
     * @param maxDifficulty Maximum difficulty time a recipe should have.
     * @param food Ingredients that a recipe should have.
     * @param tags Tags that a recipe should have.
     * @param orderBy Property by which the recipes are sorted.
     * @param orderDesc If the items should be sorted descending.
     * @param limit Maximum number of items returned.
     * @returns any OK
     * @throws ApiError
     */
    public searchRecipe(
        q?: string,
        minTime?: number,
        maxTime?: number,
        minDifficulty?: number,
        maxDifficulty?: number,
        food?: any,
        tags?: any,
        orderBy?: string,
        orderDesc?: any,
        limit?: number,
    ): CancelablePromise<{
        data: Array<RecipePreview>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/recipes',
            query: {
                'q': q,
                'minTime': minTime,
                'maxTime': maxTime,
                'minDifficulty': minDifficulty,
                'maxDifficulty': maxDifficulty,
                'food': food,
                'tags': tags,
                'orderBy': orderBy,
                'orderDesc': orderDesc,
                'limit': limit,
            },
        });
    }
    /**
     * Search Food
     * @param q Search query.
     * @param ids IDs of items.
     * @param orderBy Property by which the items are sorted.
     * @param orderDesc If the items should be sorted descending.
     * @param limit Maximum number of items returned.
     * @returns any OK
     * @throws ApiError
     */
    public searchFood(
        q?: string,
        ids?: any,
        orderBy?: string,
        orderDesc?: any,
        limit?: string,
    ): CancelablePromise<{
        data: Array<Food>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/food',
            query: {
                'q': q,
                'ids': ids,
                'orderBy': orderBy,
                'orderDesc': orderDesc,
                'limit': limit,
            },
        });
    }
    /**
     * Search Tags
     * @param q Search query.
     * @param ids IDs of items.
     * @param orderBy Property by which the items are sorted.
     * @param orderDesc If the items should be sorted descending.
     * @param limit Maximum number of items returned.
     * @returns any OK
     * @throws ApiError
     */
    public searchTags(
        q?: string,
        ids?: any,
        orderBy?: string,
        orderDesc?: any,
        limit?: string,
    ): CancelablePromise<{
        data: Array<Tag>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/tags',
            query: {
                'q': q,
                'ids': ids,
                'orderBy': orderBy,
                'orderDesc': orderDesc,
                'limit': limit,
            },
        });
    }
}
