import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { CookbookApi, UserPreview } from '../api';
import { useCookie } from '../hooks/cookies.ts';
import useSessionStorage from '../hooks/sessionStorage.ts';

type AuthContextType = {
  user?: UserPreview;
  token?: string;
  login: (email: string, password: string) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = function ({ children }: { children: ReactNode }) {
  const [token, setToken] = useCookie('token');
  const [user, setUser] = useSessionStorage<UserPreview | undefined>('user');

  const cookbookApi = useMemo(() => {
    return new CookbookApi({ BASE: import.meta.env.VITE_API_URL, TOKEN: token });
  }, [token]);

  const login = (email: string, password: string) => {
    cookbookApi.authentication
      .login({
        email: email,
        password: password,
        deviceName: import.meta.env.VITE_APP_NAME
      })
      .then(setToken);
  };

  const logout = () => {
    cookbookApi.authentication.logout();
    setToken(undefined);
    setUser(undefined);
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    cookbookApi.authentication.me().then((response) => setUser(response.data));
  }, [cookbookApi.authentication, token]);

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
