import { Icon } from '../Icon.tsx';

export function SearchInput({
  value,
  onChange
}: {
  value: string | undefined;
  onChange: (value: string) => void;
}) {
  return (
    <div className={'relative'}>
      <div className={'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'}>
        <Icon name={'search'} className={''} />
      </div>

      <input
        type="text"
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Search..."
        className={
          'mb-2 block w-full rounded-full border-none bg-surface-2 p-4 pl-10 text-sm text-on-surface hover:bg-surface-3 focus:bg-surface-3 focus:ring-primary focus-visible:outline-primary'
        }
      />
    </div>
  );
}
