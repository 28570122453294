import { useState } from 'react';
import { useSearch } from '../../../contexts/SearchContext.tsx';
import { FoodFilterModal } from './FoodFilterModal.tsx';
import { TagFilterModal } from './TagFilterModal.tsx';
import { DifficultyFilterModal } from './DifficultyFilterModal.tsx';
import { TimeFilterModal } from './TimeFilterModal.tsx';

type FilterModal = 'food' | 'tags' | 'difficulty' | 'time';

export function SearchFilters() {
  const { filters, setFilters } = useSearch();
  const [filterModal, setFilterModal] = useState<FilterModal | undefined>(undefined);

  function clearFilters() {
    setFilters({});
  }

  return (
    <div>
      <button onClick={clearFilters}>Clear Filters</button>

      <button onClick={() => setFilterModal('food')}>Food ({filters?.food?.length ?? 0})</button>
      <FoodFilterModal isOpen={filterModal === 'food'} onClose={() => setFilterModal(undefined)} />

      <button onClick={() => setFilterModal('tags')}>Tags ({filters?.tags?.length ?? 0})</button>
      <TagFilterModal isOpen={filterModal === 'tags'} onClose={() => setFilterModal(undefined)} />

      <button onClick={() => setFilterModal('difficulty')}>Difficulty</button>
      <DifficultyFilterModal
        isOpen={filterModal === 'difficulty'}
        onClose={() => setFilterModal(undefined)}
      />

      <button onClick={() => setFilterModal('time')}>Time</button>
      <TimeFilterModal isOpen={filterModal === 'time'} onClose={() => setFilterModal(undefined)} />
    </div>
  );
}
