import React, { useEffect, useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { Image } from '../../../api';
import { useCookbook } from '../../../contexts/ApiContext.tsx';
import { ControlledFormInputType } from '../../../types/ControlledFormInputType.ts';
import { twMerge } from 'tailwind-merge';
import { Image as ImageComponent } from '../Image.tsx';

export function ImageInput<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  label,
  required,
  disabled
}: {
  label?: string;
} & ControlledFormInputType<TFieldValues>) {
  const {
    field,
    fieldState: { invalid }
  } = useController<TFieldValues>({
    control: control,
    name: name,
    disabled: disabled,
    rules: { required: required }
  });

  const cookbookApi = useCookbook();
  const [image, setImage] = useState<Image | undefined>(field.value);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    setIsLoading(true);

    cookbookApi.miscellaneous
      .uploadImage({ image: e.target.files[0].slice() }) //TODO SLICE
      .then((response) => setImage(response.data))
      .catch(console.error)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    field.onChange(image);
    field.onBlur();
  }, [image]);

  return (
    <div ref={field.ref} className={'p-6'}>
      <label
        className={twMerge(
          'mb-1 block ps-1 text-sm font-medium text-on-background',
          disabled && 'text-opacity-60',
          invalid && 'text-error'
        )}
      >
        {label}
      </label>
      <div
        className={twMerge(
          'block w-full rounded-lg border border-surface-3 bg-surface-3 p-2.5 text-sm text-on-surface outline-none transition-colors hover:bg-surface-5 focus:border-primary focus:bg-surface-5 focus:ring-primary focus-visible:ring-primary',
          disabled && 'cursor-not-allowed bg-surface-1 hover:bg-surface-1',
          invalid && 'border-error focus:border-error focus-visible:ring-error'
        )}
      >
        <input type={'file'} accept={'image/*'} disabled={disabled} onChange={onChangeImage} />

        {image && <ImageComponent image={image} alt={''} />}

        {isLoading && <div>Loading...</div>}
      </div>
    </div>
  );
}
