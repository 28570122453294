import { useMemo } from 'react';

function continuedFraction(
  number: number,
  tolerance: number
): { numerator: number; denominator: number } {
  let h1 = 1;
  let h2 = 0;
  let k1 = 0;
  let k2 = 1;
  let b = 1 / number;

  do {
    b = 1 / b;
    const a = Math.floor(b);
    let aux = h1;
    h1 = a * h1 + h2;
    h2 = aux;
    aux = k1;
    k1 = a * k1 + k2;
    k2 = aux;
    b = b - a;
  } while (Math.abs(number - h1 / k1) > number * tolerance);

  return {
    numerator: h1,
    denominator: k1
  };
}

export function Fraction({ number, tolerance = 0.25 }: { number: number; tolerance?: number }) {
  const [whole, numerator, denominator] = useMemo(() => {
    let whole = Math.floor(number);
    const decimal = Math.round((number - whole) / tolerance) * tolerance;

    if (decimal === 0) {
      return [whole];
    }

    const { numerator, denominator } = continuedFraction(decimal, tolerance);

    if (numerator === denominator) {
      whole += 1;
      return [whole];
    }

    return [whole, numerator, denominator];
  }, [number, tolerance]);

  return (
    <>
      {whole != 0 && whole}

      {numerator && denominator && (
        <>
          <sup>{numerator}</sup>
          &frasl;
          <sub>{denominator}</sub>
        </>
      )}
    </>
  );
}
