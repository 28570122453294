import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCookbook } from '../../contexts/ApiContext.tsx';
import { useEffect } from 'react';
import { Input } from '../components/form/Input.tsx';
import { MeasuringType, Recipe } from '../../api';
import TopBarLayout from '../layouts/TopBarLayout.tsx';
import { ImageInput } from '../components/form/ImageInput.tsx';
import { Textarea } from '../components/form/Textarea.tsx';

type RecipeForm = Omit<Recipe, 'slug' | 'author' | 'createdAt' | 'updatedAt'>;

export default function RecipeCreatePage() {
  const cookbookApi = useCookbook();
  const navigate = useNavigate();

  const { handleSubmit, formState, control, clearErrors } = useForm<RecipeForm>();

  const {
    fields: ingredients,
    append: appendIngredient,
    remove: removeIngredient
  } = useFieldArray({ control: control, name: 'ingredients' });

  const {
    fields: instructions,
    append: appendInstruction,
    remove: removeInstruction
  } = useFieldArray({ control: control, name: 'instructions' });

  const {
    fields: tags,
    append: appendTag,
    remove: removeTag
  } = useFieldArray({ control: control, name: 'tags' });

  function onSubmit(data: RecipeForm) {
    console.log('submit', data);
    cookbookApi.recipes
      .createRecipe(data as Recipe)
      .then((response) => {
        return navigate(`/recipe/${response.data.slug}`);
      })
      .catch((error) => {
        if (error.status === 422) {
          console.log('Validation error', error.body.errors);
          clearErrors();

          // for (const [field, messages] of Object.entries(error.body.errors)) {
          //   for (const message of messages) {
          //     setError(field, {
          //       type: 'server',
          //       message: message
          //     });
          //   }
          // }
        } else {
          console.error(error);
        }
      });
  }

  useEffect(() => {
    console.log(formState);
  }, [formState]);

  return (
    <TopBarLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        {formState.errors && (
          <div className={'bg-error text-on-error'}>
            {Object.entries(formState.errors).map(([field, error]) => (
              <div key={field}>
                {field}: {error.message}
                {/*<button onClick={() => clearErrors(field)}>Clear</button>*/}
              </div>
            ))}
          </div>
        )}

        <ImageInput control={control} name={'titleImage'} />

        <Input control={control} name={'title'} label={'Titel'} />
        <Textarea control={control} name={'description'} label={'Beschreibung'} />
        <Input control={control} name={'servings'} label={'Portionen'} />
        <Input control={control} name={'preparationTime'} label={'Vorbereitungszeit'} />
        <Input control={control} name={'difficulty'} label={'Schwierigkeit'} />
        <Input control={control} name={'isPublic'} label={'Öffentlich'} />

        <h2>Ingredients</h2>
        {ingredients.map((ingredient, index) => (
          <div key={ingredient.id}>
            <Input control={control} name={`ingredients.${index}.amount`} label={'Amount'} />
            <Input
              control={control}
              name={`ingredients.${index}.measuringType`}
              label={'Measurement'}
            />
            <Input control={control} name={`ingredients.${index}.food.name`} label={'Name'} />
            <button onClick={() => removeIngredient(index)}>Remove Ingredient</button>
          </div>
        ))}

        <button
          onClick={() =>
            appendIngredient({
              food: { name: '' },
              measuringType: MeasuringType.UNKNOWN,
              amount: 0
            })
          }
        >
          Add Ingredient
        </button>

        <h2>Instructions</h2>

        {instructions.map((instruction, index) => (
          <div key={instruction.id}>
            <ImageInput control={control} name={`instructions.${index}.image`} />
            <Textarea control={control} name={`instructions.${index}.description`} label={'Text'} />
            <button onClick={() => removeInstruction(index)}>Remove Instruction</button>
          </div>
        ))}

        <button onClick={() => appendInstruction({ image: undefined, description: '' })}>
          Add Instruction
        </button>

        <h2>Tags</h2>

        {tags.map((tag, index) => (
          <div key={tag.id}>
            <Input control={control} name={`tags.${index}.name`} label={'Name'} />
            <button onClick={() => removeTag(index)}>Remove Tag</button>
          </div>
        ))}

        <button onClick={() => appendTag({ name: '' })}>Add Tag</button>

        <button>Create</button>
      </form>
    </TopBarLayout>
  );
}
