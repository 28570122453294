'use client';

import React, {
  Attributes,
  ForwardedRef,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';
import { twMerge } from 'tailwind-merge';

export interface SliderRef {
  scrollToItem: (index: number, smooth?: boolean) => void;
  itemRefs: (HTMLDivElement | null)[];
}

/**
 * Slider-Container utilizing:
 *  - CSS-Scroll-Snapping
 *  - IntersectionObserver to determine the active item
 *  - ScrollIntoView to scroll to the active item
 *
 *  @param children SliderItems
 *  @param itemRefs Refs of the slider items to get the current active item from
 *  @param activeItem Index of the initially active item
 *  @param onSlide Callback when the active item changes
 * @param ref
 */
function SliderComponent(
  {
    children,
    activeItem,
    onSlide,
    className
  }: {
    children: ReactNode;
    activeItem?: number;
    onSlide?: (index: number) => void;
    className?: string;
  },
  ref: ForwardedRef<SliderRef | undefined>
) {
  const container = useRef<HTMLDivElement>(null);
  const refs = useRef<(HTMLDivElement | null)[]>([]);

  // Clone the children and add a ref to each child
  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ref: (el: HTMLDivElement) => {
          refs.current[index] = el;
        }
      } as Partial<unknown> & Attributes);
    }

    return child;
  });

  // Observe scrolling to determine the active item
  useEffect(() => {
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      const entry = entries.find((e) => e.isIntersecting);

      if (entry) {
        const index = refs.current.indexOf(entry.target as HTMLDivElement);
        onSlide && onSlide(index);
      }
    };

    // Observer fires when the item is >50% visible
    const observer = new IntersectionObserver(handleIntersect, {
      root: null,
      threshold: 0.5
    });

    const items = refs.current.filter((item) => item);

    items.forEach((item) => item && observer.observe(item));

    return () => {
      items.forEach((item) => item && observer.unobserve(item));
    };
  }, [children, onSlide]);

  // Initial scroll to the active item
  useEffect(() => {
    activeItem && scrollToItem(activeItem);
  });

  // Expose scrollToItem function
  useImperativeHandle(ref, () => ({
    scrollToItem(index: number, smooth: boolean = false) {
      scrollToItem(index, smooth);
    },
    itemRefs: refs.current
  }));

  function scrollToItem(index: number, smooth: boolean = false) {
    if (index >= 0 && index < refs.current.length) {
      container.current?.scrollTo({
        left: refs.current[index]?.offsetLeft,
        behavior: smooth ? 'smooth' : 'auto'
      });
    }
  }

  return (
    <div
      ref={container}
      className={twMerge(
        'flex h-full w-full flex-grow snap-x snap-mandatory overflow-x-auto scrollbar-hidden',
        className
      )}
    >
      {childrenWithProps}
    </div>
  );
}

export const Slider = forwardRef(SliderComponent);
