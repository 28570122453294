import { ReactNode } from 'react';

export function TimeLineItem({ title, children }: { title: string; children: ReactNode }) {
  return (
    <li className="mb-10 ms-4">
      <div className="absolute -start-1.5 mt-1.5 h-3 w-3 rounded-full border border-background bg-outline" />
      <span className="text-sm font-normal leading-none text-outline">{title}</span>
      <p className="mb-4">{children}</p>
    </li>
  );
}
