import { AnimatedIcon } from '../AnimatedIcon.tsx';
import { ReactNode, RefObject } from 'react';
import { NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { MenuItemBackgroundTransition } from '../transitions/MenuItemBackgroundTransition.tsx';
import { TransitionGroup } from 'react-transition-group';

export function MenuItem({
  to,
  icon,
  children,
  className
}: {
  to: string;
  icon: string;
  children: ReactNode;
  className?: string;
}) {
  return (
    <NavLink to={to} className={twMerge('w-full flex-grow md:flex-grow-0', className)}>
      {({ isActive }) => (
        <>
          <PillMenuItem icon={icon} isActive={isActive} className={twMerge('lg:hidden', className)}>
            {children}
          </PillMenuItem>

          <FilledMenuItem
            icon={icon}
            isActive={isActive}
            className={twMerge('hidden lg:flex', className)}
          >
            {children}
          </FilledMenuItem>
        </>
      )}
    </NavLink>
  );
}

function PillMenuItem({
  icon,
  isActive,
  children,
  className
}: {
  icon: string;
  isActive: boolean;
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        className,
        'group flex flex-col items-center text-on-surface-variant',
        isActive && 'font-semibold text-on-secondary-container'
      )}
    >
      <div className={'relative h-8 w-16'}>
        {/* Background with Hover-Opacity-transition */}
        <div
          className={
            'absolute inset-0 rounded-full bg-on-surface-variant bg-opacity-0 transition-opacity duration-200 group-hover:bg-opacity-5 group-active:bg-opacity-10'
          }
        />

        {/* Background with Grow/Shrink-transition */}
        <TransitionGroup>
          {isActive && (
            <MenuItemBackgroundTransition>
              {(ref: RefObject<HTMLDivElement>) => (
                <div ref={ref} className={'absolute inset-0 rounded-full bg-secondary-container'} />
              )}
            </MenuItemBackgroundTransition>
          )}
        </TransitionGroup>

        {/* Icon */}
        <AnimatedIcon
          name={icon}
          filled={isActive}
          className={'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xl'}
        />
      </div>

      {/* Content */}
      <div
        className={twMerge(
          'flex-grow text-center text-sm',
          isActive && 'font-semibold text-on-surface'
        )}
      >
        {children}
      </div>
    </div>
  );
}

function FilledMenuItem({
  icon,
  isActive,
  children,
  className
}: {
  icon: string;
  isActive: boolean;
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className={twMerge('group relative h-12', className)}>
      {/* Background with Hover-Opacity-transition */}
      <div
        className={
          'absolute inset-0 rounded-full bg-on-surface-variant bg-opacity-0 transition-opacity duration-200 group-hover:bg-opacity-5 group-active:bg-opacity-10'
        }
      />

      {/* Background with Grow/Shrink-transition */}
      <TransitionGroup>
        {isActive && (
          <MenuItemBackgroundTransition>
            {(ref: RefObject<HTMLDivElement>) => (
              <div ref={ref} className={'absolute inset-0 rounded-full bg-secondary-container'} />
            )}
          </MenuItemBackgroundTransition>
        )}
      </TransitionGroup>

      {/* Content */}
      <div
        className={twMerge(
          'absolute inset-0 flex flex-row items-center gap-1.5 px-3 py-2 text-on-surface-variant',
          isActive && 'text-on-secondary-container'
        )}
      >
        <AnimatedIcon name={icon} filled={isActive} className={'text-xl'} />

        <div className={twMerge('text-sm', isActive && 'font-semibold')}>{children}</div>
      </div>
    </div>
  );
}
