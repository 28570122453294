import { useAuth } from '../../contexts/AuthContext.tsx';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import TopBarLayout from '../layouts/TopBarLayout.tsx';

type LoginForm = {
  email: string;
  password: string;
};

export default function LoginPage() {
  const navigate = useNavigate();
  const { user, login } = useAuth();

  const { register, handleSubmit } = useForm<LoginForm>();

  const onSubmit: SubmitHandler<LoginForm> = (data) => login(data.email, data.password);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  return (
    <TopBarLayout>
      <h1>Login</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <input type={'email'} placeholder={'Email'} {...register('email', { required: true })} />

        <input
          type={'password'}
          placeholder={'Password'}
          {...register('password', { required: true })}
        />

        <button type={'submit'}>Login</button>
      </form>
    </TopBarLayout>
  );
}
