import { Modal } from '../../components/Modal.tsx';
import { ReactNode } from 'react';

export function SearchFilterModal({
  isOpen,
  onClose,
  onApply,
  onClear,
  children
}: {
  isOpen: boolean;
  onClose: () => void;
  onApply: () => void;
  onClear: () => void;
  children: ReactNode;
}) {
  function Footer() {
    return (
      <div>
        <button onClick={onClear}>Clear</button>
        <button onClick={onApply}>Apply</button>
      </div>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} footer={Footer()}>
      {children}
    </Modal>
  );
}
