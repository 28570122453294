import { ForwardedRef, forwardRef, ReactNode, RefObject, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames, CSSTransitionProps } from 'react-transition-group/CSSTransition';

function Fade<Ref extends undefined | HTMLElement>(
  {
    children,
    ...rest
  }: {
    children: (ref: RefObject<Ref>) => ReactNode;
  } & CSSTransitionProps<Ref>,
  ref: ForwardedRef<CSSTransition<Ref>>
) {
  const nodeRef = useRef<Ref>(null);

  const classNames: CSSTransitionClassNames = {
    enter: 'transition ease-out duration-200 opacity-0',
    enterActive: '!opacity-100',
    exit: 'transition ease-out duration-200 opacity-100',
    exitActive: '!opacity-0'
  };

  return (
    <CSSTransition ref={ref} nodeRef={nodeRef} timeout={200} classNames={classNames} {...rest}>
      {children(nodeRef)}
    </CSSTransition>
  );
}

export const FadeTransition = forwardRef(Fade);
