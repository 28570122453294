import { SearchFilterModal } from './SearchFilterModal.tsx';
import { useSearch } from '../../../contexts/SearchContext.tsx';
import { forwardRef, Ref, useImperativeHandle, useRef, useState } from 'react';

type TimeFilterModalContentRef = {
  getSelectedTime: () => { minTime: number | undefined; maxTime: number | undefined };
};

export function TimeFilterModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const { filters, setFilters } = useSearch();
  const contentRef = useRef<TimeFilterModalContentRef>();

  function apply() {
    const { minTime, maxTime } = contentRef.current?.getSelectedTime() ?? {};
    setFilters({ ...filters, minTime: minTime, maxTime: maxTime });
    onClose();
  }

  function clear() {
    setFilters({ ...filters, minTime: undefined, maxTime: undefined });
    onClose();
  }

  return (
    <SearchFilterModal isOpen={isOpen} onClose={onClose} onApply={apply} onClear={clear}>
      <TimeFilterModalContent ref={contentRef} />
    </SearchFilterModal>
  );
}

const TimeFilterModalContent = forwardRef(
  (_: unknown, ref: Ref<TimeFilterModalContentRef | undefined>) => {
    const { filters } = useSearch();
    const [minTime, setMinTime] = useState(filters.minTime);
    const [maxTime, setMaxTime] = useState(filters.maxTime);

    useImperativeHandle(
      ref,
      () => ({
        getSelectedTime: () => ({ minTime, maxTime })
      }),
      [minTime, maxTime]
    );

    return (
      <>
        <h2>Time</h2>
        <div>
          <input
            type={'number'}
            value={minTime ?? ''}
            onChange={(e) => setMinTime(Number(e.target.value))}
            placeholder={'Min'}
          />
          <input
            type={'number'}
            value={maxTime ?? ''}
            onChange={(e) => setMaxTime(Number(e.target.value))}
            placeholder={'Max'}
          />
        </div>
      </>
    );
  }
);
