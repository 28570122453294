import { ForwardedRef, forwardRef, ReactNode, RefObject, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames, CSSTransitionProps } from 'react-transition-group/CSSTransition';

function GrowSlide<Ref extends undefined | HTMLElement>(
  {
    children,
    ...rest
  }: {
    children: (ref: RefObject<Ref>) => ReactNode;
  } & CSSTransitionProps<Ref>,
  ref: ForwardedRef<CSSTransition<Ref>>
) {
  const nodeRef = useRef<Ref>(null);

  const classNames: CSSTransitionClassNames = {
    enter:
      'transition ease-out duration-300 transform translate-y-full md:translate-y-1/4 opacity-0',
    enterActive: '!translate-y-0 !opacity-100',
    exit: 'transition ease-out duration-300 transform translate-y-0 opacity-100',
    exitActive: '!translate-y-full md:!translate-y-1/4 !opacity-0'
  };

  return (
    <CSSTransition ref={ref} nodeRef={nodeRef} timeout={300} classNames={classNames} {...rest}>
      {children(nodeRef)}
    </CSSTransition>
  );
}

export const ModalCardTransition = forwardRef(GrowSlide);
