import { useState } from 'react';

export default function useSessionStorage<Type>(
  key: string,
  defaultValue?: Type
): [Type | undefined, (value: Type | undefined) => void] {
  const initialValue = () => {
    try {
      const value = window.sessionStorage.getItem(key);

      return value ? (JSON.parse(value) as Type) : defaultValue;
    } catch (error) {
      return defaultValue;
    }
  };

  const [item, setInnerValue] = useState<Type | undefined>(initialValue);

  const setValue = (value: Type | undefined) => {
    try {
      setInnerValue(value);
      window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error(e);
    }
  };

  return [item, setValue];
}
