import { MenuItem } from './MenuItem.tsx';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/icon.svg';
import { useTranslation } from 'react-i18next';

export function Menu() {
  const { t } = useTranslation(['dashboard', 'search', 'editor', 'collections', 'profile']);

  return (
    <nav
      className={
        'fixed bottom-0 left-0 z-40 h-20 w-full bg-surface-1 p-2 md:h-screen md:w-24 md:bg-background md:py-4 lg:w-60 lg:px-4 lg:py-8'
      }
    >
      <div
        className={
          'flex h-full w-full flex-row justify-around gap-4 md:flex-col md:items-center md:justify-start lg:gap-2'
        }
      >
        <Link to={'/'} className={'hidden md:flex md:flex-col md:items-center lg:mb-4'}>
          <img src={Logo} alt={'Cookbook'} className={'h-10 lg:h-14'} />
          <h1 className={'hidden font-baloo text-3xl lg:block'}>Kochbuch</h1>
        </Link>

        <MenuItem to={'/'} icon={'home'}>
          {t('dashboard:title')}
        </MenuItem>
        <MenuItem to={'/search'} icon={'search'}>
          {t('search:title')}
        </MenuItem>
        <MenuItem to={'/favorites'} icon={'favorite'}>
          {t('collections:title')}
        </MenuItem>
        <MenuItem to={'/profile'} icon={'person'} className={'lg:mt-auto'}>
          {t('profile:title')}
        </MenuItem>
      </div>
    </nav>
  );
}
