import { useEffect, useState } from 'react';
import { ApiError, CancelablePromise, CookbookApi } from '../api';
import { useCookbook } from '../contexts/ApiContext.tsx';

export function useCookbookLoader<Type>(
  loaderCallback: (api: CookbookApi) => CancelablePromise<{ data: Type }>,
  dependencies: readonly unknown[],
  initialValue?: Type
): [boolean, Type | undefined, ApiError | undefined, () => void] {
  const cookbookApi = useCookbook();
  const [cancelablePromise, setCancelablePromise] = useState<
    CancelablePromise<{ data: Type }> | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<Type | undefined>(initialValue);
  const [error, setError] = useState<ApiError | undefined>(undefined);

  useEffect(() => {
    // Handle the promise
    if (cancelablePromise) {
      setIsLoading(true);
      setError(undefined);

      cancelablePromise
        .then((response) => setResult(response.data))
        .catch(setError)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [cancelablePromise]);

  useEffect(() => {
    // Cancel the promise
    return () => {
      if (cancelablePromise) {
        cancelablePromise.cancel();
      }
    };
  }, []);

  useEffect(() => {
    load();
  }, [cookbookApi, ...dependencies]);

  function load() {
    if (cancelablePromise) {
      cancelablePromise.cancel();
    }

    const promise = loaderCallback(cookbookApi);
    setCancelablePromise(promise);
  }

  return [isLoading, result, error, load];
}
