import { ReactNode } from 'react';
import BaseLayout from './BaseLayout.tsx';
import { Icon } from '../components/Icon.tsx';
import { TextButton } from '../components/button/TextButton.tsx';

export default function TopBarLayout({ title, children }: { title?: string; children: ReactNode }) {
  const goBack = () => {
    window.history.back();
  }

  return (
    <BaseLayout>
      <div className={'min-h-full min-w-full pt-16'}>
        <header
          className={
            'fixed left-0 top-0 z-10 h-16 w-full border-b border-outline bg-background text-on-background'
          }
        >
          <div className={'mx-auto flex h-full w-full max-w-4xl flex-row items-center px-4'}>
            <TextButton onClick={goBack} className={'p-1 h-full aspect-square rounded-full'}>
              <Icon name={'arrow_back'} />
            </TextButton>
            <h1 className={'ms-2 text-black flex-grow text-xl font-semibold uppercase'}>
              {title ?? ''}
            </h1>
          </div>
        </header>

        <main className={'container mx-auto w-full max-w-4xl p-4'}>{children}</main>
      </div>
    </BaseLayout>
  );
}
