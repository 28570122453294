'use client';

import React, { createContext, ReactNode, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { ToastMessageHost } from '../views/components/toast/ToastMessageHost.tsx';

type ToastMessageContextType = {
  messages: ToastMessage[];
  pushMessage: (message: string, icon?: ReactNode) => string;
  removeMessage: (messageId: string) => void;
};

export type ToastMessage = {
  id: string;
  message: string;
  icon?: ReactNode;
};

const ToastMessageContext = createContext({} as ToastMessageContextType);

export const ToastMessageProvider = ({ children }: { children: React.ReactNode }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);
  const toastMessageRoot = document.body;

  const pushMessage = (message: string, icon?: ReactNode) => {
    const messageId = Math.random().toString(36).substring(7);

    setMessages([
      ...messages,
      {
        id: messageId,
        message,
        icon
      }
    ]);

    return messageId;
  };

  const removeMessage = (messageId: string) => {
    setMessages((messages) => messages.filter((message) => message.id !== messageId));
  };

  return (
    <ToastMessageContext.Provider
      value={{
        messages,
        pushMessage,
        removeMessage
      }}
    >
      {children}
      {toastMessageRoot && createPortal(<ToastMessageHost />, toastMessageRoot)}
    </ToastMessageContext.Provider>
  );
};

export const useToastMessages = () => useContext(ToastMessageContext);
