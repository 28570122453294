import { ReactNode } from 'react';
import { Menu } from '../components/menu/Menu.tsx';
import BaseLayout from './BaseLayout.tsx';

export default function AppBarLayout({ children }: { children: ReactNode }) {
  return (
    <BaseLayout>
      <div className={'min-h-full w-full pb-20 md:pb-0 md:pl-24 lg:pl-60'}>
        <main className={'mx-auto w-full max-w-4xl p-4'}>{children}</main>

        <Menu />
      </div>
    </BaseLayout>
  );
}
