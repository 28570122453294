/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AuthenticationService } from './services/AuthenticationService';
import { CollectionsService } from './services/CollectionsService';
import { MiscellaneousService } from './services/MiscellaneousService';
import { RecipesService } from './services/RecipesService';
import { SearchService } from './services/SearchService';
import { UsersService } from './services/UsersService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class CookbookApi {
    public readonly authentication: AuthenticationService;
    public readonly collections: CollectionsService;
    public readonly miscellaneous: MiscellaneousService;
    public readonly recipes: RecipesService;
    public readonly search: SearchService;
    public readonly users: UsersService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://cookbook.test/api/v1',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.authentication = new AuthenticationService(this.request);
        this.collections = new CollectionsService(this.request);
        this.miscellaneous = new MiscellaneousService(this.request);
        this.recipes = new RecipesService(this.request);
        this.search = new SearchService(this.request);
        this.users = new UsersService(this.request);
    }
}

