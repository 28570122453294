import { twMerge } from 'tailwind-merge';

export function AnimatedIcon({
  name,
  filled = false,
  className
}: {
  name: string;
  filled?: boolean;
  className?: string;
}) {
  return (
    <i className={twMerge('material-symbol', filled && 'icon-style-filled-fade-in', className)}>
      {name}
    </i>
  );
}
