'use client';

import { ToastMessage } from './ToastMessage.tsx';
import { useToastMessages } from '../../../contexts/ToastMessageContext.tsx';

export function ToastMessageHost() {
  const { messages } = useToastMessages();

  return (
    <div
      className={
        'pointer-events-none fixed bottom-10 z-50 flex w-full flex-col flex-wrap-reverse items-center gap-4 p-4'
      }
    >
      {messages.map((message) => (
        <ToastMessage message={message} key={message.id} />
      ))}
    </div>
  );
}
