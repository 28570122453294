/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DashboardSection = {
    readonly title: string;
    readonly type: DashboardSection.type;
    data: Record<string, any>;
};
export namespace DashboardSection {
    export enum type {
        RECIPE_CARD = 'recipe_card',
        RECIPE_CAROUSEL = 'recipe_carousel',
        COLLECTION_CARD = 'collection_card',
        COLLECTION_CAROUSEL = 'collection_carousel',
        USER_CARD = 'user_card',
        USER_CAROUSEL = 'user_carousel',
    }
}

