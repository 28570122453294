import { LoadingSkeleton } from './LoadingSkeleton.tsx';
import { twMerge } from 'tailwind-merge';
import { SkeletonProps } from 'react-loading-skeleton';

interface BlockLoadingSkeletonProps extends SkeletonProps {
  className?: string;
}

export function BlockLoadingSkeleton({ className, ...props }: BlockLoadingSkeletonProps) {
  return (
    <LoadingSkeleton
      {...props}
      containerClassName={twMerge('block', className)}
      className={'block h-full w-full'}
    />
  );
}
