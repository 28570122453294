import { Image as ApiImage } from '../../api';
import { DetailedHTMLProps, ImgHTMLAttributes, useEffect, useMemo, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

export function Image({
  image,
  alt,
  loading = 'lazy',
  className
}: {
  image: ApiImage;
  alt: string;
  loading?: 'lazy' | 'eager';
  className?: string;
} & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
  const imageRef = useRef<HTMLImageElement>(null);

  const srcSet = useMemo(() => {
    const sizes = image.sizes.map((imageSize) => {
      return `${imageSize.url} ${imageSize.width}w`;
    });

    sizes.push(`${image.thumbnail} 32w`);

    return sizes.join(', ');
  }, [image]);

  useEffect(() => {
    setSizesAttribute();
    window.addEventListener('resize', setSizesAttribute);

    return () => {
      window.removeEventListener('resize', setSizesAttribute);
    };
  }, []);

  const setSizesAttribute = () => {
    if (imageRef.current) {
      const image = imageRef.current;
      const size = image.clientWidth;

      image.sizes = Math.ceil((size / window.innerWidth) * 100) + 'vw';
    }
  };

  return (
    <img
      ref={imageRef}
      alt={alt}
      loading={loading}
      src={image.originalUrl}
      srcSet={srcSet}
      width={image.originalWidth}
      height={image.originalHeight}
      sizes={'1px'}
      className={twMerge('object-cover', className)}
    />
  );
}
