import { forwardRef, ReactNode, Ref } from 'react';

function SliderItemComponent(
  {
    children
  }: {
    children: ReactNode;
  },
  ref: Ref<HTMLDivElement>
) {
  return (
    <div
      className={'flex h-full w-full flex-none snap-start snap-always flex-col justify-between'}
      ref={ref}
    >
      {children}
    </div>
  );
}

export const SliderItem = forwardRef(SliderItemComponent);
