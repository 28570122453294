import { MeasuringUnit, MeasuringUnitCategories } from '../enum/measuringTypes.ts';
import { MeasuringType } from '../api';

export function calculateMaximalUnit(
  quantity: number,
  measuringType: MeasuringType
): {
  quantity: number;
  unit: MeasuringUnit;
} {
  const units = MeasuringUnitCategories[measuringType] as MeasuringUnit[] | undefined;
  let maxUnit = units
    ?.sort((a, b) => a.baseUnitMultiplier - b.baseUnitMultiplier)
    .filter((unit) => unit.baseUnitMultiplier <= quantity)
    .pop();

  if (!maxUnit) {
    maxUnit = units?.[0];
  }

  if (!maxUnit) {
    maxUnit = MeasuringUnitCategories[MeasuringType.UNKNOWN][0];
  }

  return {
    quantity: quantity / maxUnit.baseUnitMultiplier,
    unit: maxUnit
  };
}
