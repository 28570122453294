import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { ProfileImage } from '../cookbook/user/ProfileImage.tsx';

export default function ProfilePage() {
  const { user, logout } = useAuth();

  return (
    <>
      <div className={'text-center'}>
        <ProfileImage user={user} className={'text-9xl'} />
      </div>

      {user ? (
        <>
          <p>{user.username}</p>
          <button onClick={logout}>Logout</button>
          <Link to={'/recipe/create'}>Neues Rezept</Link>
        </>
      ) : (
        <>
          <Link to={'/login'}>Login</Link>
        </>
      )}
    </>
  );
}
