/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Recipe } from '../models/Recipe';
import type { RecipeCollectionPreview } from '../models/RecipeCollectionPreview';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UsersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get User
     * @param username
     * @returns any OK
     * @throws ApiError
     */
    public getUser(
        username: string,
    ): CancelablePromise<{
        data: User;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{username}',
            path: {
                'username': username,
            },
        });
    }
    /**
     * Get Recipes of a User
     * @param username
     * @returns any OK
     * @throws ApiError
     */
    public getUserRecipes(
        username: string,
    ): CancelablePromise<{
        data: Array<Recipe>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{username}/recipes',
            path: {
                'username': username,
            },
        });
    }
    /**
     * Get Collections of a User
     * Get all lists.
     * @param username
     * @returns any OK
     * @throws ApiError
     */
    public getUserCollections(
        username: string,
    ): CancelablePromise<{
        data: Array<RecipeCollectionPreview>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{username}/collections',
            path: {
                'username': username,
            },
        });
    }
}
