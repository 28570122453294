// @ts-ignore
import { useCookies } from 'react-cookie';
// @ts-ignore
import { CookieSetOptions } from 'universal-cookie';

/**
 * Custom hook to get and set a query param. Wraps react-router-dom's useSearchParams hook.
 */
export function useCookie(name: string): [string | undefined, (value: string | undefined) => void] {
  const [cookies, setCookies] = useCookies([name]);

  const getCookie = (name: string): string | undefined => {
    return cookies[name] || '';
  };

  const setCookie = (name: string, value: string | undefined, options?: CookieSetOptions): void => {
    setCookies(name, value, options);
  };

  return [getCookie(name), (value: string | undefined) => setCookie(name, value)];
}
