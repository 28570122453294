import { useMemo } from 'react';

export function Time({ time }: { time: Date }) {
  const minutes = useMemo(() => {
    const hours = time.getHours();
    const minutes = time.getMinutes();

    return hours * 60 + minutes;
  }, [time]);

  return <>{minutes} minutes</>;
}
