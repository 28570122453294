import app_de from './de/app.json';
import auth_de from './de/auth.json';
import collections_de from './de/collections.json';
import dashboard_de from './de/dashboard.json';
import editor_de from './de/editor.json';
import profile_de from './de/profile.json';
import search_de from './de/search.json';
import app_en from './en/app.json';
import auth_en from './en/auth.json';
import collections_en from './en/collections.json';
import dashboard_en from './en/dashboard.json';
import editor_en from './en/editor.json';
import profile_en from './en/profile.json';
import search_en from './en/search.json';

const configuration = {
  interpolation: { escapeValue: false },
  lng: 'de',
  resources: {
    de: {
      app: app_de,
      auth: auth_de,
      collections: collections_de,
      dashboard: dashboard_de,
      editor: editor_de,
      profile: profile_de,
      search: search_de
    },
    en: {
      app: app_en,
      auth: auth_en,
      collections: collections_en,
      dashboard: dashboard_en,
      editor: editor_en,
      profile: profile_en,
      search: search_en
    }
  }
};

export default configuration;
