'use client';

import { useEffect } from 'react';
import {
  ToastMessage as ToastMessageType,
  useToastMessages
} from '../../../contexts/ToastMessageContext.tsx';

export function ToastMessage({
  message,
  timeout = 3000
}: {
  message: ToastMessageType;
  timeout?: number;
}) {
  const { removeMessage } = useToastMessages();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      removeMessage(message.id);
    }, timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div
      className={
        'pointer-events-auto flex w-full max-w-2xl flex-row items-center gap-3 rounded-lg bg-inverse-surface p-4 text-on-inverse-surface shadow-md'
      }
    >
      {message.icon}
      {message.message}
    </div>
  );
}
