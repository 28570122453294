import { useTranslation } from 'react-i18next';
import { DashboardSection, RecipePreview } from '../../api';
import { RecipePreviewCard } from '../cookbook/RecipePreviewCard.tsx';
import { SliderItem } from '../components/slider/SliderItem.tsx';
import { SliderWithControls } from '../components/slider/SliderWithControls.tsx';
import { useCookbookLoader } from '../../hooks/loader.ts';
import { RecipePreviewCardLoading } from '../cookbook/RecipePreviewCardLoading.tsx';

export default function DashboardPage() {
  const { t } = useTranslation(['dashboard']);
  const [isLoading, dashboardItems] = useCookbookLoader(
    (api) => api.miscellaneous.getDashboard(),
    []
  );

  return (
    <>
      <h1 className={'font-baloo text-2xl font-black'}>
        {t('dashboard:welcome')}
      </h1>

      {isLoading ? (
        <div className={'grid grid-cols-2 gap-3 md:grid-cols-3'}>
          {[1, 2, 3].map((_, index) => (
            <RecipePreviewCardLoading key={index} />
          ))}
        </div>
      ) : (
        dashboardItems?.map((item, index) => (
          <div key={index}>
            {item.title}

            {item.type === DashboardSection.type.RECIPE_CAROUSEL && (
              <div>
                <SliderWithControls>
                  {item.data?.map((recipe: RecipePreview) => (
                    <SliderItem key={recipe.slug}>
                      <RecipePreviewCard recipe={recipe} />
                    </SliderItem>
                  ))}
                </SliderWithControls>
              </div>
            )}

            {item.type === DashboardSection.type.RECIPE_CAROUSEL && (
              <div className={'grid grid-cols-2 gap-3 md:grid-cols-3'}>
                {item.data?.map((recipe: RecipePreview) => (
                  <RecipePreviewCard recipe={recipe} key={recipe.slug} />
                ))}
              </div>
            )}
          </div>
        ))
      )}
    </>
  );
}
