/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RecipeCollection } from '../models/RecipeCollection';
import type { RecipeCollectionPreview } from '../models/RecipeCollectionPreview';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CollectionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Collections of a User
     * Get all lists.
     * @param username
     * @returns any OK
     * @throws ApiError
     */
    public getUserCollections(
        username: string,
    ): CancelablePromise<{
        data: Array<RecipeCollectionPreview>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{username}/collections',
            path: {
                'username': username,
            },
        });
    }
    /**
     * Create a Collection
     * @param requestBody
     * @returns RecipeCollection OK
     * @throws ApiError
     */
    public createCollection(
        requestBody?: RecipeCollection,
    ): CancelablePromise<RecipeCollection> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/collection',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `You need to login to make this request.`,
            },
        });
    }
    /**
     * Get a Collection
     * @param slug
     * @returns any OK
     * @throws ApiError
     */
    public getCollection(
        slug: string,
    ): CancelablePromise<{
        data: RecipeCollection;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/collection/{slug}',
            path: {
                'slug': slug,
            },
            errors: {
                403: `You don't have the required permissions to make this request.`,
            },
        });
    }
    /**
     * Update a Collection
     * @param slug
     * @param requestBody
     * @returns RecipeCollection OK
     * @throws ApiError
     */
    public updateCollection(
        slug: string,
        requestBody?: RecipeCollection,
    ): CancelablePromise<RecipeCollection> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/collection/{slug}',
            path: {
                'slug': slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `You need to login to make this request.`,
                403: `You don't have the required permissions to make this request.`,
            },
        });
    }
    /**
     * Delete a Collection
     * @param slug
     * @returns any OK
     * @throws ApiError
     */
    public deleteCollection(
        slug: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/collection/{slug}',
            path: {
                'slug': slug,
            },
            errors: {
                401: `You need to login to make this request.`,
                403: `You don't have the required permissions to make this request.`,
            },
        });
    }
    /**
     * Add a Recipe to a Collection
     * @param slug
     * @returns any OK
     * @throws ApiError
     */
    public addCollectionRecipe(
        slug: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/collection/{slug}/recipe',
            path: {
                'slug': slug,
            },
            errors: {
                401: `You need to login to make this request.`,
                403: `You don't have the required permissions to make this request.`,
            },
        });
    }
    /**
     * Remove a Recipe from a Collection
     * @param slug
     * @returns any OK
     * @throws ApiError
     */
    public deleteCollectionRecipes(
        slug: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/collection/{slug}/recipe',
            path: {
                'slug': slug,
            },
            errors: {
                401: `You need to login to make this request.`,
                403: `You don't have the required permissions to make this request.`,
            },
        });
    }
}
