import { useParams } from 'react-router-dom';
import { useCookbook } from '../../contexts/ApiContext.tsx';
import { useEffect, useState } from 'react';
import { User } from '../../api';
import { Helmet } from 'react-helmet';
import TopBarLayout from '../layouts/TopBarLayout.tsx';

export default function UserPage() {
  const cookbookApi = useCookbook();
  const { id } = useParams();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (!id) {
      //TODO: Redirect to 404
      return;
    }

    cookbookApi.users
      .getUser(id)
      .then((response) => setUser(response.data))
      .catch((error) => {
        console.log(error);
      });
  }, [cookbookApi, id]);

  return (
    <TopBarLayout>
      <Helmet>
        <title>{user?.username}</title>
      </Helmet>

      <h1 className={'font-baloo text-2xl font-black'}>{user?.username}</h1>
    </TopBarLayout>
  );
}
