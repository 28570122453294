import { twMerge } from 'tailwind-merge';
import { FieldValues, useController } from 'react-hook-form';
import { ControlledFormInputType } from '../../../types/ControlledFormInputType.ts';

export function Input<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  label,
  required,
  disabled
}: {
  label?: string;
} & ControlledFormInputType<TFieldValues>) {
  const {
    field,
    fieldState: { invalid }
  } = useController<TFieldValues>({
    control: control,
    name: name,
    disabled: disabled,
    rules: { required: required }
  });

  return (
    <div className={'p-6'}>
      <label
        htmlFor={field.name}
        className={twMerge(
          'mb-1 block ps-1 text-sm font-medium text-on-background',
          disabled && 'text-opacity-60',
          invalid && 'text-error'
        )}
      >
        {label}
      </label>
      <input
        ref={field.ref}
        //type={type}
        name={field.name}
        id={field.name}
        required={required}
        disabled={disabled}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value ?? ''}
        className={twMerge(
          'block w-full rounded-lg border border-surface-3 bg-surface-3 p-2.5 text-sm text-on-surface outline-none transition-colors hover:bg-surface-5 focus:border-primary focus:bg-surface-5 focus:ring-primary focus-visible:ring-primary',
          disabled && 'cursor-not-allowed bg-surface-1 hover:bg-surface-1',
          invalid && 'border-error focus:border-error focus-visible:ring-error'
        )}
        //placeholder={placeholder}
      />
    </div>
  );
}
