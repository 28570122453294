import { BlockLoadingSkeleton } from '../components/loaders/BlockLoadingSkeleton.tsx';
import { LoadingSkeleton } from '../components/loaders/LoadingSkeleton.tsx';

export function RecipePreviewCardLoading() {
  return (
    <object className={'flex flex-col rounded-2xl bg-surface-1 p-2 pb-4'}>
      <BlockLoadingSkeleton className={'aspect-4/3 rounded-xl'} />

      <h5 className={'mx-2 mt-2 flex-grow text-on-surface'}>
        <LoadingSkeleton />
      </h5>

      <div className={'m-2 text-on-surface text-opacity-60'}>
        <span>
          <LoadingSkeleton />
        </span>
        <span className={'float-right'}>
          <LoadingSkeleton />
        </span>
      </div>
    </object>
  );
}
