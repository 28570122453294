/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Recipe } from '../models/Recipe';
import type { RecipePreview } from '../models/RecipePreview';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RecipesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create a Recipe
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public createRecipe(
        requestBody?: Recipe,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/recipe',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `You need to login to make this request.`,
            },
        });
    }
    /**
     * Get a Recipe
     * @param slug
     * @returns any OK
     * @throws ApiError
     */
    public getRecipe(
        slug: string,
    ): CancelablePromise<{
        data: Recipe;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/recipe/{slug}',
            path: {
                'slug': slug,
            },
            errors: {
                403: `You don't have the required permissions to make this request.`,
            },
        });
    }
    /**
     * Update a Recipe
     * @param slug
     * @returns Recipe OK
     * @throws ApiError
     */
    public updateRecipe(
        slug: string,
    ): CancelablePromise<Recipe> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/recipe/{slug}',
            path: {
                'slug': slug,
            },
            errors: {
                401: `You need to login to make this request.`,
                403: `You don't have the required permissions to make this request.`,
            },
        });
    }
    /**
     * Delete a Recipe
     * @param slug
     * @returns any OK
     * @throws ApiError
     */
    public deleteRecipe(
        slug: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/recipe/{slug}',
            path: {
                'slug': slug,
            },
            errors: {
                401: `You need to login to make this request.`,
                403: `You don't have the required permissions to make this request.`,
            },
        });
    }
    /**
     * Get Recipes of a User
     * @param username
     * @returns any OK
     * @throws ApiError
     */
    public getUserRecipes(
        username: string,
    ): CancelablePromise<{
        data: Array<Recipe>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{username}/recipes',
            path: {
                'username': username,
            },
        });
    }
    /**
     * Add a Recipe to a Collection
     * @param slug
     * @returns any OK
     * @throws ApiError
     */
    public addCollectionRecipe(
        slug: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/collection/{slug}/recipe',
            path: {
                'slug': slug,
            },
            errors: {
                401: `You need to login to make this request.`,
                403: `You don't have the required permissions to make this request.`,
            },
        });
    }
    /**
     * Remove a Recipe from a Collection
     * @param slug
     * @returns any OK
     * @throws ApiError
     */
    public deleteCollectionRecipes(
        slug: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/collection/{slug}/recipe',
            path: {
                'slug': slug,
            },
            errors: {
                401: `You need to login to make this request.`,
                403: `You don't have the required permissions to make this request.`,
            },
        });
    }
    /**
     * Search Recipes
     * @param q Search query.
     * @param minTime Minimum preparation time a recipe should have.
     * @param maxTime Maximum preparation time a recipe should have.
     * @param minDifficulty Minimum difficulty a recipe should have.
     * @param maxDifficulty Maximum difficulty time a recipe should have.
     * @param food Ingredients that a recipe should have.
     * @param tags Tags that a recipe should have.
     * @param orderBy Property by which the recipes are sorted.
     * @param orderDesc If the items should be sorted descending.
     * @param limit Maximum number of items returned.
     * @returns any OK
     * @throws ApiError
     */
    public searchRecipe(
        q?: string,
        minTime?: number,
        maxTime?: number,
        minDifficulty?: number,
        maxDifficulty?: number,
        food?: any,
        tags?: any,
        orderBy?: string,
        orderDesc?: any,
        limit?: number,
    ): CancelablePromise<{
        data: Array<RecipePreview>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/recipes',
            query: {
                'q': q,
                'minTime': minTime,
                'maxTime': maxTime,
                'minDifficulty': minDifficulty,
                'maxDifficulty': maxDifficulty,
                'food': food,
                'tags': tags,
                'orderBy': orderBy,
                'orderDesc': orderDesc,
                'limit': limit,
            },
        });
    }
}
