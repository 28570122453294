import { createBrowserRouter, Outlet } from 'react-router-dom';
import DashboardPage from '../views/pages/DashboardPage.tsx';
import SearchPage from '../views/pages/SearchPage.tsx';
import FavoritesPage from '../views/pages/FavoritesPage.tsx';
import ProfilePage from '../views/pages/ProfilePage.tsx';
import NotFound from '../views/pages/errors/404.tsx';
import RecipePage from '../views/pages/RecipePage.tsx';
import { ProviderLayout } from '../views/layouts/ProviderLayout.tsx';
import UserPage from '../views/pages/UserPage.tsx';
import LoginPage from '../views/pages/LoginPage.tsx';
import RecipeCreatePage from '../views/pages/RecipeCreatePage.tsx';
import { SearchProvider } from '../contexts/SearchContext.tsx';
import AppBarLayout from '../views/layouts/AppBarLayout.tsx';

export const router = createBrowserRouter([
  {
    element: (
      <ProviderLayout>
        <Outlet />
      </ProviderLayout>
    ),
    children: [
      {
        element: (
          <AppBarLayout>
            <Outlet />
          </AppBarLayout>
        ),
        children: [
          {
            path: '/',
            element: <DashboardPage />
          },
          {
            path: '/search',
            element: (
              <SearchProvider>
                <SearchPage />
              </SearchProvider>
            )
          },
          {
            path: '/favorites',
            element: <FavoritesPage />
          },
          {
            path: '/profile',
            element: <ProfilePage />
          }
        ]
      },
      {
        path: '/recipe/create',
        element: <RecipeCreatePage />
      },
      {
        path: '/recipe/:id',
        element: <RecipePage />
      },
      {
        path: '/user/:id',
        element: <UserPage />
      },
      {
        path: 'login',
        element: <LoginPage />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]);
