import { useSearch } from '../../../contexts/SearchContext.tsx';
import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Tag } from '../../../api';
import { useThrottledState } from '../../../hooks/throttledState.ts';
import { useCookbookLoader } from '../../../hooks/loader.ts';
import { SearchFilterModal } from './SearchFilterModal.tsx';
import { useCookbook } from '../../../contexts/ApiContext.tsx';
import { BlockLoadingSkeleton } from '../../components/loaders/BlockLoadingSkeleton.tsx';

type TagFilterModalContentRef = {
  getSelectedTags: () => string[] | undefined;
};

export function TagFilterModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const { filters, setFilters } = useSearch();
  const contentRef = useRef<TagFilterModalContentRef>();

  function apply() {
    const selectedTags = contentRef.current?.getSelectedTags();
    setFilters({ ...filters, tags: selectedTags });
    onClose();
  }

  function clear() {
    setFilters({ ...filters, tags: [] });
    onClose();
  }

  return (
    <SearchFilterModal isOpen={isOpen} onClose={onClose} onApply={apply} onClear={clear}>
      <TagFilterModalContent ref={contentRef} />
    </SearchFilterModal>
  );
}

const TagFilterModalContent = forwardRef(
  (_: unknown, ref: Ref<TagFilterModalContentRef | undefined>) => {
    const cookbookApi = useCookbook();
    const { filters } = useSearch();

    const [initialSelectedTagsLoaded, setInitialSelectedTagsLoaded] = useState(false);
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const [searchQuery, throttledSearchQuery, setSearchQuery] = useThrottledState('', 500);

    const [isLoading, tags, error] = useCookbookLoader(
      (api) => api.search.searchTags(throttledSearchQuery),
      [throttledSearchQuery]
    );

    useEffect(() => {
      if (filters.tags) {
        cookbookApi.search
          .searchTags(undefined, Object.assign({}, filters.tags))
          .then((result) => setSelectedTags(result.data))
          .catch((error) => console.error(error))
          .finally(() => setInitialSelectedTagsLoaded(true));
      } else {
        setInitialSelectedTagsLoaded(true);
      }
    }, []);

    useEffect(() => {
      if (error) console.error(error);
    }, [error]);

    useImperativeHandle(
      ref,
      () => ({
        getSelectedTags: () =>
          selectedTags.map((tag) => tag.id).filter((id) => id !== undefined) as string[]
      }),
      [selectedTags]
    );

    return !initialSelectedTagsLoaded ? (
      <>
        <BlockLoadingSkeleton />
      </>
    ) : (
      <>
        <input type={'text'} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />

        <ul>
          {selectedTags?.map((tag) => (
            <li key={tag.id}>
              <button
                onClick={() => {
                  setSelectedTags(selectedTags.filter((selected) => selected.id !== tag.id));
                }}
              >
                {tag.name}
              </button>
            </li>
          ))}
        </ul>

        <hr />

        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <ul>
            {tags?.map((tag) => (
              <li key={tag.id}>
                <label>
                  <input
                    type={'checkbox'}
                    checked={selectedTags.some((selected) => selected.id === tag.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedTags([...selectedTags, tag]);
                      } else {
                        setSelectedTags(selectedTags.filter((selected) => selected.id !== tag.id));
                      }
                    }}
                  />
                  {tag.name}
                </label>
              </li>
            ))}
          </ul>
        )}
      </>
    );
  }
);
