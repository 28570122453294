import { useSearch } from '../../../contexts/SearchContext.tsx';
import { forwardRef, Ref, useImperativeHandle, useRef, useState } from 'react';
import { SearchFilterModal } from './SearchFilterModal.tsx';

type DifficultyFilterModalContentRef = {
  getSelectedDifficulty: () => {
    minDifficulty: number | undefined;
    maxDifficulty: number | undefined;
  };
};

export function DifficultyFilterModal({
  isOpen,
  onClose
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { filters, setFilters } = useSearch();
  const contentRef = useRef<DifficultyFilterModalContentRef>();

  function apply() {
    const { minDifficulty, maxDifficulty } = contentRef.current?.getSelectedDifficulty() ?? {};
    setFilters({ ...filters, minDifficulty: minDifficulty, maxDifficulty: maxDifficulty });
    onClose();
  }

  function clear() {
    setFilters({ ...filters, minDifficulty: undefined, maxDifficulty: undefined });
    onClose();
  }

  return (
    <SearchFilterModal isOpen={isOpen} onClose={onClose} onApply={apply} onClear={clear}>
      <DifficultyFilterModalContent ref={contentRef} />
    </SearchFilterModal>
  );
}

const DifficultyFilterModalContent = forwardRef(
  (_: unknown, ref: Ref<DifficultyFilterModalContentRef | undefined>) => {
    const { filters } = useSearch();
    const [minDifficulty, setMinDifficulty] = useState(filters.minDifficulty);
    const [maxDifficulty, setMaxDifficulty] = useState(filters.maxDifficulty);

    useImperativeHandle(
      ref,
      () => ({
        getSelectedDifficulty: () => ({ minDifficulty, maxDifficulty })
      }),
      [minDifficulty, maxDifficulty]
    );

    return (
      <>
        <h2>Difficulty</h2>
        <div>
          <input
            type={'number'}
            value={minDifficulty ?? ''}
            onChange={(e) => setMinDifficulty(Number(e.target.value))}
            placeholder={'Min'}
          />
          <input
            type={'number'}
            value={maxDifficulty ?? ''}
            onChange={(e) => setMaxDifficulty(Number(e.target.value))}
            placeholder={'Max'}
          />
        </div>
      </>
    );
  }
);
