import { SearchInput } from '../components/search/SearchInput.tsx';
import { SearchFilters } from '../cookbook/search/SearchFilters.tsx';
import { useSearch } from '../../contexts/SearchContext.tsx';
import { RecipePreviewCardLoading } from '../cookbook/RecipePreviewCardLoading.tsx';
import { RecipePreviewCard } from '../cookbook/RecipePreviewCard.tsx';

export default function SearchPage() {
  const { search, setSearch, isLoading, results } = useSearch();

  return (
    <>
      <SearchInput value={search} onChange={setSearch} />
      <SearchFilters />
      <div className={'grid grid-cols-2 gap-3 md:grid-cols-3'}>

        {isLoading && results?.length === 0 ? (
          <>
            {[1, 2, 3].map((_, index) => (
              <RecipePreviewCardLoading key={index} />
            ))}
          </>
        ) : (
          results?.map((recipe) => (
            <RecipePreviewCard recipe={recipe} key={recipe.slug} />
          ))
        )}

      </div>
    </>
  );
}
