import { CookbookApiProvider } from '../../contexts/ApiContext.tsx';
import { ReactNode } from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import translations from '../../lang/translations.ts';
import { AuthProvider } from '../../contexts/AuthContext.tsx';
import { ToastMessageProvider } from '../../contexts/ToastMessageContext.tsx';
// @ts-ignore
import { CookiesProvider } from 'react-cookie';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { QueryParamProvider } from 'use-query-params';

export function ProviderLayout({ children }: { children: ReactNode }) {
  i18next.init(translations);

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <I18nextProvider i18n={i18next}>
          <AuthProvider>
            <CookbookApiProvider>
              <ToastMessageProvider>{children}</ToastMessageProvider>
            </CookbookApiProvider>
          </AuthProvider>
        </I18nextProvider>
      </CookiesProvider>
    </QueryParamProvider>
  );
}
