import { User } from '../../../api';
import { Image } from '../../components/Image.tsx';
import { Icon } from '../../components/Icon.tsx';
import { twMerge } from 'tailwind-merge';

export function ProfileImage({ user, className }: {
  user: User | undefined,
  className?: string
}) {
  return (
    <i
      className={twMerge('inline-flex justify-center items-center size-[1em] align-bottom rounded-full overflow-hidden bg-surface-2', className)}>
      {user?.profileImage ? (
        <Image
          image={user.profileImage}
          alt={user.username}
          className={'block size-full'} />
      ) : (
        <Icon
          name={'person'}
          className={'!text-[0.5em]'} />
      )}
    </i>
  );
}