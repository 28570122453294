import TopBarLayout from '../../layouts/TopBarLayout.tsx';
import { useLocation } from 'react-router-dom';

function NotFound() {
  const location = useLocation();

  return (
    <TopBarLayout>
      404
      {location.state?.error ? <p>{location.state.error}</p> : <p>Page not found</p>}
    </TopBarLayout>
  );
}

export default NotFound;
