import { createContext, ReactNode, useContext, useEffect } from 'react';
import { Recipe } from '../api';
import { useThrottledState } from '../hooks/throttledState.ts';
import { useCookbookLoader } from '../hooks/loader.ts';
import { useToastMessages } from './ToastMessageContext.tsx';
import { ArrayParam, NumberParam, StringParam, useQueryParam } from 'use-query-params';

type SearchContextType = {
  search: string | undefined;
  setSearch: (search: string) => void;
  filters: Filters;
  setFilters: (filters: Filters) => void;
  isLoading: boolean;
  results: Recipe[] | undefined;
};

export type Filters = {
  minTime?: number;
  maxTime?: number;
  minDifficulty?: number;
  maxDifficulty?: number;
  food?: string[];
  tags?: string[];
};

const SearchContext = createContext<SearchContextType>({} as SearchContextType);

export const SearchProvider = function ({ children }: { children: ReactNode }) {
  const { pushMessage } = useToastMessages();

  const [searchQuery, setSearchQuery] = useQueryParam('search', StringParam);
  const [minTimeQuery, setMinTimeQuery] = useQueryParam('minTime', NumberParam);
  const [maxTimeQuery, setMaxTimeQuery] = useQueryParam('maxTime', NumberParam);
  const [minDifficultyQuery, setMinDifficultyQuery] = useQueryParam('minDifficulty', NumberParam);
  const [maxDifficultyQuery, setMaxDifficultyQuery] = useQueryParam('maxDifficulty', NumberParam);
  const [foodQuery, setFoodQuery] = useQueryParam('food', ArrayParam);
  const [tagsQuery, setTagsQuery] = useQueryParam('tags', ArrayParam);

  const [search, throttledSearch, setSearch] = useThrottledState(searchQuery, 500);

  const [isLoading, results, error] = useCookbookLoader<Recipe[]>(
    (api) =>
      api.search.searchRecipe(
        search ?? undefined,
        minTimeQuery ?? undefined,
        maxTimeQuery ?? undefined,
        minDifficultyQuery ?? undefined,
        maxDifficultyQuery ?? undefined,
        Object.assign({}, foodQuery),
        Object.assign({}, tagsQuery),
        undefined,
        undefined,
        20
      ),
    [
      throttledSearch,
      minTimeQuery,
      maxTimeQuery,
      minDifficultyQuery,
      maxDifficultyQuery,
      foodQuery?.sort().join(','),
      tagsQuery?.sort().join(',')
    ]
  );

  useEffect(() => {
    setSearchQuery(throttledSearch, 'replaceIn');
  }, [throttledSearch]);

  useEffect(() => {
    setSearch(searchQuery ?? '');
  }, [searchQuery]);

  useEffect(() => {
    if (error) {
      pushMessage(error.message);
    }
  }, [error]);

  return (
    <SearchContext.Provider
      value={{
        search: search ?? undefined,
        setSearch: setSearch,
        filters: {
          minTime: minTimeQuery ?? undefined,
          maxTime: maxTimeQuery ?? undefined,
          minDifficulty: minDifficultyQuery ?? undefined,
          maxDifficulty: maxDifficultyQuery ?? undefined,
          food: (foodQuery?.filter((food) => food !== null) as string[]) ?? undefined,
          tags: (tagsQuery?.filter((tag) => tag !== null) as string[]) ?? undefined
        },
        setFilters: (filters: Filters) => {
          setMinTimeQuery(filters.minTime, 'replaceIn');
          setMaxTimeQuery(filters.maxTime, 'replaceIn');
          setMinDifficultyQuery(filters.minDifficulty, 'replaceIn');
          setMaxDifficultyQuery(filters.maxDifficulty, 'replaceIn');
          setFoodQuery(filters.food, 'replaceIn');
          setTagsQuery(filters.tags, 'replaceIn');
        },
        isLoading: isLoading,
        results: results
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
